import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Settings from './components/Settings';
import ResponseInsight from './components/ResponseInsight';
import EventHistory from './components/EventHistory';
import Queue from './components/Queue';
import Dashboard from './components/Dashboard';
import Cases from './components/Cases';
import ActionSetup from './components/ActionSetup';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Settings />} />
        <Route path="/responseinsight" element={<ResponseInsight />} />
        <Route path="/cases" element={<Cases />} />
        <Route path="/eventhistory" element={<EventHistory />} />
        <Route path="/queue" element={<Queue />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/actionsetup" element={<ActionSetup />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;

