import React, { useState, useEffect } from 'react'; 
import 'tailwindcss/tailwind.css'; 
import '../App.css'; 
import axios from 'axios'; 
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa'; 
import { useSearchParams } from 'react-router-dom';

const EventHistory = () => {
  const [searchParams] = useSearchParams();
  const tenantGroupId = searchParams.get('tenantGroupId');
  const region = searchParams.get('region');
  const eventId = searchParams.get('eventId');
  const [eventHistory, setEventHistory] = useState([]);
  const [expandedHistoryDetails, setExpandedHistoryDetails] = useState({});
  const [expandedPrompts, setExpandedPrompts] = useState({});
  const [expandedTools, setExpandedTools] = useState({});
  const [expandedResponses, setExpandedResponses] = useState({});
  const [expandedMedia, setExpandedMedia] = useState({});

  const baseUrl =  'https://virtualoperator.eastus.cloudapp.azure.com/api'; //  'http://127.0.0.1:5001';  //  

  useEffect(() => {
    const fetchEventHistory = async () => {
      try {
        const response = await axios.get(`${baseUrl}/eventHistory?eventId=${eventId}&tenantGroupId=${tenantGroupId}&region=${region}`);
        setEventHistory(response.data);
      } catch (error) {
        console.error('Error fetching event history:', error);
      }
    };
    const intervalId = setInterval(fetchEventHistory, 1000); // Fetch event history every 3 seconds

    fetchEventHistory(); // Fetch event history initially

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [eventId, tenantGroupId, region, baseUrl]);

  const handleToggleHistoryDetail = (historyId) => {
    setExpandedHistoryDetails((prevState) => {
      if (prevState[historyId]) {
        const newState = { ...prevState };
        delete newState[historyId];
        return newState;
      } else {
        return { ...prevState, [historyId]: true };
      }
    });
  };

  const handleTogglePrompt = (historyId) => {
    setExpandedPrompts((prevState) => {
      if (prevState[historyId]) {
        const newState = { ...prevState };
        delete newState[historyId];
        return newState;
      } else {
        return { ...prevState, [historyId]: true };
      }
    });
  };

  const handleToggleTool = (historyId) => {
    setExpandedTools((prevState) => {
      if (prevState[historyId]) {
        const newState = { ...prevState };
        delete newState[historyId];
        return newState;
      } else {
        return { ...prevState, [historyId]: true };
      }
    });
  };

  const handleToggleResponse = (historyId) => {
    setExpandedResponses((prevState) => {
      if (prevState[historyId]) {
        const newState = { ...prevState };
        delete newState[historyId];
        return newState;
      } else {
        return { ...prevState, [historyId]: true };
      }
    });
  };

  const handleToggleMedia = (historyId) => {
    setExpandedMedia((prevState) => {
      if (prevState[historyId]) {
        const newState = { ...prevState };
        delete newState[historyId];
        return newState;
      } else {
        return { ...prevState, [historyId]: true };
      }
    });
  };

  return (
    <div className="w-full min-h-screen bg-neutral-800 p-4">
      <h1 className="text-2xl font-bold mb-4 text-white">Event History: {eventId}</h1>
      {eventHistory.length === 0 ? (
        <div className="text-center text-white">No Virtual Operators have interacted with this event</div>
      ) : (
        <div className="bg-neutral-600 divide-y divide-neutral-500 rounded-md text-white overflow-y-auto">
          {eventHistory.map((history, index) => (
            <div key={index}>
              <div className="ml-1 flex justify-between items-start p-2">
                <div className="mx-2">
                  {history.content}
                  <div className="flex justify-between items-center text-xs italic">
                    <div>
                      {new Intl.DateTimeFormat(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                      }).format(new Date(history.created))}
                    </div>
                  </div>
                </div>
                {(history.type === "llm_driven" || (history.media && history.media.length > 0)) && (
                  <button
                    onClick={() => handleToggleHistoryDetail(history.id)}
                    className="p-1 m-2 rounded-md font-semibold hover:bg-gray-500"
                  >
                    {expandedHistoryDetails[history.id] ? <FaMinusSquare /> : <FaPlusSquare />}
                  </button>
                )}
              </div>
              {expandedHistoryDetails[history.id] && (
                <div className="bg-neutral-500 px-2 pt-2 rounded-md overflow-auto mx-2 mb-2">
                  {history.media && history.media.length > 0 && (
                    <>
                      <h4 className="font-bold text-sm cursor-pointer" onClick={() => handleToggleMedia(history.id)}>
                        {expandedMedia[history.id] ? 'Media (Show)' : 'Media (Hide)'}
                      </h4>
                      {!expandedMedia[history.id] && (
                        <div className="flex space-x-4 overflow-x-scroll items-start px-2 pt-1">
                          {history.media.map((image, index) => (
                            <div key={index} className="flex-none text-center">
                              <img
                                src={`${baseUrl}/getImage/${image.eventId}/${image.imageName}?tenantGroupId=${tenantGroupId}&region=${region}`}
                                alt="key frame"
                                className="max-w-xs mx-auto"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                  {history.prompt && (
                    <>
                      <h4 className="font-bold text-sm cursor-pointer" onClick={() => handleTogglePrompt(history.id)}>
                        {expandedPrompts[history.id] ? 'Prompt (Hide)' : 'Prompt (Show)'}
                      </h4>
                      {expandedPrompts[history.id] && <pre className="text-xs">{history.prompt}</pre>}
                    </>
                  )}
                  {history.functions && (
                    <>
                      <h4 className="font-bold text-sm cursor-pointer" onClick={() => handleToggleTool(history.id)}>
                        {expandedTools[history.id] ? 'Tools (Hide)' : 'Tools (Show)'}
                      </h4>
                      {expandedTools[history.id] && <pre className="text-xs">{JSON.stringify(history.functions, null, 2)}</pre>}
                    </>
                  )}
                  {history.rawResponse && (
                    <>
                      <h4 className="font-bold text-sm cursor-pointer" onClick={() => handleToggleResponse(history.id)}>
                        {expandedResponses[history.id] ? 'Response (Hide)' : 'Response (Show)'}
                      </h4>
                      {expandedResponses[history.id] && <pre className="text-xs">{JSON.stringify(history.rawResponse, null, 2)}</pre>}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventHistory;