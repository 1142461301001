import { BaseEdge, EdgeLabelRenderer, getStraightPath, MarkerType } from 'reactflow';

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, label, markerEnd}) => {
  const [edgePath, labelX, labelY] = getStraightPath({ sourceX, sourceY, targetX, targetY });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={{stroke: "#fff", zIndex: 1000}}/>
      {label && (
        <EdgeLabelRenderer>
          <div
            className="bg-neutral-700 text-white text-xs rounded px-2 py-1"
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              pointerEvents: 'none',
              zIndex: 1001
            }}
          >
            {label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default CustomEdge;