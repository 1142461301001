import React, { useState, useEffect, useCallback } from 'react';
import 'tailwindcss/tailwind.css';
import '../App.css';
import axios from 'axios';
import ReactFlow, { Controls, Background, useNodesState, useEdgesState, addEdge, MarkerType, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import CustomEdge from './CustomEdge';
import CustomEdgeRequired from './CustomEdgeRequired';

const CustomReactFlow = ({ children, ...props }) => (
  <ReactFlowProvider>
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlow
        {...props}
        style={{
          ...props.style,
          zIndex: 0,
        }}
      >
        {children}
      </ReactFlow>
    </div>
  </ReactFlowProvider>
);

const edgeTypes = {
  custom: CustomEdge,
  customReq: CustomEdgeRequired,
};

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength) + '...';
  }
}

const ActionPlanEditor = () => {
  const [actionPlans, setActionPlans] = useState([]);
  const [selectedActionPlan, setSelectedActionPlan] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [taskCategories, setTaskCategories] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [tenantGroupId, setTenantGroupId] = useState(null);
  const [region, setRegion] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [planTasks, setPlanTasks] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isFlowchartVisible, setIsFlowchartVisible] = useState(true);
  const [isActionsVisible, setIsActionsVisible] = useState(true);
  const [suggestedActionPlans, setSuggestedActionPlans] = useState([]);

  const handleChatInputChange = (event) => {
    setChatInput(event.target.value);
  };


  const handleSendMessage = async () => {
    if (chatInput.trim() !== '') {
      const newMessage = {
        role: 'user',
        content: chatInput,
      };

      setChatMessages((prevMessages) => [...prevMessages, newMessage]);
      setChatInput('');

      const modifiedUniquePlanTasks = uniquePlanTasks.map((task) => {
        const taskCategory = taskCategories.find((category) => category.taskCategoryID === task.taskCategoryID)?.title || '';
        const taskType = taskTypes.find((type) => type.taskTypeID === task.taskTypeID)?.title || '';

        const simplifiedTriggers = task.triggers?.map((trigger) => ({
          required: trigger.required,
          resultCondition: trigger.resultCondition,
          triggerTaskId: trigger.trigger.taskID,
        })) || [];

        return {
          rootAction: task.scripts && task.scripts[selectedActionPlan.scriptID] !== undefined,
          requiredRootAction: task.scripts && task.scripts[selectedActionPlan.scriptID] !== undefined && task.scripts[selectedActionPlan.scriptID].required,
          taskCategory,
          taskData: task.taskData,
          taskID: task.taskID,
          taskText: task.taskText,
          taskType,
          triggers: simplifiedTriggers,
        };
      });

      try {
        const response = await axios.post(`${baseUrl}/actionAssistantRequest?tenantGroupId=${tenantGroupId}&region=${region}`, {
          conversationHistory: [...chatMessages, newMessage],
          uniquePlanTasks: modifiedUniquePlanTasks,
        });

        const assistantMessage = {
          role: 'assistant',
          content: response.data.reply,
        };

        setChatMessages((prevMessages) => [...prevMessages, assistantMessage]);

        // Extract and store suggested Action Plans
        const suggestedPlans = extractActionPlans(response.data.reply);
        setSuggestedActionPlans((prevPlans) => [...prevPlans, ...suggestedPlans]);
      } catch (error) {
        console.error('Error sending message to Action Assistant:', error);
      }
    }
  };


  const extractActionPlans = (content) => {
    const regex = /```json\s*([\s\S]*?)\s*```/g;
    const matches = [...content.matchAll(regex)];
    return matches.map((match) => {
      try {
        return JSON.parse(match[1]);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return null;
      }
    }).filter(Boolean);
  };

  const renderMessage = (message, index) => {
    if (message.role === 'assistant') {
      const parts = message.content.split(/(```json[\s\S]*?```)/);
      return (
        <div key={index} className="mb-2 text-left">
          {parts.map((part, partIndex) => {
            if (part.startsWith('```json') && part.endsWith('```')) {
              const jsonContent = part.replace(/```json|```/g, '').trim();
              return (
                <div key={`${index}-${partIndex}`} className="mt-2">
                  <button
                    onClick={() => handleActionPlanClick(JSON.parse(jsonContent))}
                    className="px-4 py-2 mb-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 focus:outline-none"
                  >
                    View Suggested Action Plan
                  </button>
                </div>
              );
            } else {
              return (
                <span key={`${index}-${partIndex}`} className="inline-block p-2 rounded-lg bg-neutral-700">
                  {part}
                </span>
              );
            }
          })}
        </div>
      );
    } else {
      return (
        <div key={index} className="mb-2 text-right">
          <span className="inline-block p-2 rounded-lg bg-blue-600">
            {message.content}
          </span>
        </div>
      );
    }
  };

  const handleActionPlanClick = (actionPlan) => {
    console.log(actionPlan)
    const mockScriptID = 0;
    const convertedTasks = actionPlan.map(task => ({
      ...task,
      taskCategoryID: taskCategories.find(cat => cat.title === task.taskCategory)?.taskCategoryID || 0,
      taskTypeID: taskTypes.find(type => type.title === task.taskType)?.taskTypeID || 0,
      scripts: {
        [mockScriptID]: {
          required: task.requiredRootAction || false,
          rootTask: task.rootAction || false,
        }
      },
      triggers: task.triggers.map(trigger => ({
        ...trigger,
        trigger: {
          taskID: trigger.triggerTaskId
        }
      }))
    }));

    const placeholderActionPlan = {
      scriptID: mockScriptID,
      title: "Suggested Action Plan",
      description: "This is a suggested Action Plan from the AI assistant.",
      tasks: convertedTasks
    };

    setSelectedActionPlan(placeholderActionPlan);
    setTasks(convertedTasks);
  };

  const baseUrl =  'https://virtualoperator.eastus.cloudapp.azure.com/api'; //  'http://127.0.0.1:5001';  //  

  useEffect(() => {
    const url = new URL(window.location.href);
    const tenantId = url.searchParams.get('tenantGroupId');
    setTenantGroupId(tenantId);
    const regionCode = url.searchParams.get('region');
    setRegion(regionCode);
    //console.log('Tenant Group ID:', tenantId);
    //console.log('Region:', regionCode);
  }, []);

  useEffect(() => {
    const fetchActionPlanData = async () => {
      if (tenantGroupId && region) {
        try {
          const response = await axios.get(`${baseUrl}/actionPlanData?tenantGroupId=${tenantGroupId}&region=${region}`);
          const { scripts, tasks, taskCategories, taskTypes } = response.data;
          setActionPlans(scripts);
          setTasks(tasks);
          setTaskCategories(taskCategories);
          setTaskTypes(taskTypes);
          //console.log('Action Plan Data fetched successfully');
        } catch (error) {
          console.error('Error fetching Action Plan data:', error);
        }
      }
    };

    fetchActionPlanData();
  }, [tenantGroupId, region]);

  const handleActionPlanChange = (event) => {
    const selectedScriptId = parseInt(event.target.value);
    const selectedPlan = actionPlans.find((plan) => plan.scriptID === selectedScriptId);
    setSelectedActionPlan(selectedPlan);
    //console.log('Selected Action Plan:', selectedPlan);
  };

  const uniquePlanTasks = [...new Set(planTasks.map((task) => task.taskID))].map((taskID) => planTasks.find((task) => task.taskID === taskID));

  useEffect(() => {
    if (selectedActionPlan) {
      const filteredTasks = tasks.filter((task) => task.scripts && task.scripts[selectedActionPlan.scriptID]);

      const buildElements = (task, scriptId, parentId = null, depth = 0, parentY = 0, visitedTasks = new Set(), triggerCondition = "", rootTasks = new Set(), rootEdges = [], triggerRequired = false) => {
        const allTasks = [];
        const visitedTasksClone = new Set(visitedTasks);

        if (!visitedTasksClone.has(task.taskID)) {
          visitedTasksClone.add(task.taskID);
          allTasks.push(task);

          const nodeId = `task-${task.taskID}`;
          const node = {
            id: nodeId,
            data: {
              label: (
                <div className="p-2 bg-neutral-700 rounded text-white">
                  <h4 className="text-sm font-bold mb-1">{truncateText(task.taskText, 80)}</h4>
                  {task.taskTypeID && <p className="text-xs mb-1">Type: {taskTypes.find((type) => type.taskTypeID === task.taskTypeID)?.title || ''}</p>}
                  {task.taskCategoryID && <p className="text-xs mb-1">Category: {taskCategories.find((category) => category.taskCategoryID === task.taskCategoryID)?.title || ''}</p>}
                  {task.scripts[scriptId]?.required && <p className="text-xs mb-1">Required: Yes</p>}
                  {(task.scripts && task.scripts[selectedActionPlan.scriptID] !== undefined) && <p className="text-xs mb-1">Root Action: Yes</p>}
                </div>
              ),
            },
            position: { x: depth * 290, y: parentY },
            style: {
              width: 200,
              height: 'auto',
            },
            sourcePosition: 'right',
            targetPosition: 'left',
          };

          const edges = [];

          if (parentId) {
            const edgeId = `${parentId}-${nodeId}-${triggerCondition}`;
            edges.push({
              id: edgeId,
              source: parentId,
              target: nodeId,
              type: triggerRequired ? 'customReq' : 'custom',
              label: triggerCondition,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 40,
                height: 40,
              },
            });
          }

          let currentY = parentY;

          const childElements = task.triggers?.reduce((acc, trigger) => {
            const triggerCondition = trigger.resultCondition;
            const triggerRequired = trigger.required;
            const triggerTask = tasks.find((t) => t.taskID === trigger.trigger.taskID);
            if (triggerTask && triggerTask.scripts) {
              if (rootTasks.has(triggerTask.taskID)) {
                const edgeId = `${nodeId}-${`task-${triggerTask.taskID}`}-${triggerCondition}`;
                acc.edges.push({
                  id: edgeId,
                  source: nodeId,
                  target: `task-${triggerTask.taskID}`,
                  type: triggerRequired ? 'customReq' : 'custom',
                  label: triggerCondition,
                  markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 40,
                    height: 40,
                  },
                });
              } else {
                const { nodes: childNodes, edges: childEdges, allTasks: childAllTasks } = buildElements(triggerTask, scriptId, nodeId, depth + 1, currentY, visitedTasksClone, triggerCondition, rootTasks, rootEdges, triggerRequired);

                acc.nodes.push(...childNodes);
                acc.edges.push(...childEdges);
                acc.allTasks.push(...childAllTasks);

                currentY += 200;
              }
            }
            return acc;
          }, { nodes: [], edges: [], allTasks: [] }) || { nodes: [], edges: [], allTasks: [] };

          return { nodes: [node, ...childElements.nodes], edges: [...edges, ...childElements.edges, ...rootEdges], allTasks: [...allTasks, ...childElements.allTasks] };
        } else {
          return { nodes: [], edges: [], allTasks: [...allTasks] };
        }
      };

      const rootTasks = new Set(filteredTasks.map((task) => task.taskID));
      const { nodes, edges, allTasks } = filteredTasks.reduce((acc, task, index) => {
        const { nodes: taskNodes, edges: taskEdges, allTasks: taskAllTasks } = buildElements(task, selectedActionPlan.scriptID, null, 0, index * 200, new Set(), "", rootTasks, []);
        acc.nodes.push(...taskNodes);
        acc.edges.push(...taskEdges);
        acc.allTasks.push(...taskAllTasks);
        return acc;
      }, { nodes: [], edges: [], allTasks: [] });

      setNodes(nodes);
      setEdges(edges);
      setPlanTasks(allTasks);
      //console.log('Nodes updated:', nodes);
      //console.log('Edges updated:', edges);
    } else {
      setNodes([]);
      setEdges([]);
      setPlanTasks([]);
      //console.log('No Action Plan selected, nodes and edges cleared');
    }
  }, [selectedActionPlan, tasks, taskCategories, taskTypes]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  return (
    <div className="bg-neutral-900 text-white min-h-screen flex items-center justify-center">
      <div className="max-w-7xl w-full">
        <div className="bg-neutral-800 p-4 rounded-lg shadow-lg m-4">
          <h1 className="text-2xl font-bold mb-2">Action Setup</h1>
          <div className="mb-3">
            <label htmlFor="actionPlanSelect" className="block text-sm font-bold text-white">
              Select Action Plan
            </label>
            <select
              id="actionPlanSelect"
              value={selectedActionPlan ? selectedActionPlan.scriptID : ''}
              onChange={handleActionPlanChange}
              className="mt-1 block w-full py-2 px-3 bg-neutral-700 text-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="">Create a new Action Plan</option>
              {actionPlans.map((plan) => (
                <option key={plan.scriptID} value={plan.scriptID}>
                  {plan.title}
                </option>
              ))}
              {selectedActionPlan && selectedActionPlan.scriptID === 0 && (
                <option value={0}>Suggested Action Plan</option>
              )}
            </select>
          </div>
          <div className="mt-2">
            <div className="flex items-center mb-2">
              <h2 className="text-sm font-bold">Action Assistant</h2>
            </div>
            {chatMessages.length > 0 && (
              <div className="border border-neutral-700 rounded-md p-4 max-h-96 overflow-y-auto mb-4">
                {chatMessages.map((message, index) => renderMessage(message, index))}
              </div>
            )}

            <div className="flex">
              <input
                type="text"
                value={chatInput}
                onChange={handleChatInputChange}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
                className="flex-grow px-4 py-2 bg-neutral-700 text-white rounded-l-md focus:outline-none"
                placeholder="Type your message..."
              />
              <button
                onClick={handleSendMessage}
                className="px-4 py-2 bg-blue-600 text-white rounded-r-md hover:bg-blue-500 focus:outline-none"
              >
                Send
              </button>
            </div>
          </div>
          {selectedActionPlan && (
            <>
              <div className="mt-3">
                <div className="flex items-center mb-2">
                  <h2 className="text-sm font-bold mb-0">Flowchart</h2>
                  <button
                    className="ml-2 focus:outline-none"
                    onClick={() => setIsFlowchartVisible(!isFlowchartVisible)}
                  >
                    {isFlowchartVisible ? '▲' : '▼'}
                  </button>
                </div>
                {isFlowchartVisible && (
                  <div className="border border-neutral-700 rounded-md h-[calc(100vh-400px)]">
                    <CustomReactFlow
                      nodes={nodes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={onConnect}
                      nodesDraggable={true}
                      fitView
                      edgeTypes={edgeTypes}
                    >
                      <Background color="#444" gap={16} />
                      <Controls />
                    </CustomReactFlow>
                  </div>
                )}
              </div>
              <div className="mt-2">
                <div className="flex items-center mb-2">
                  <h2 className="text-sm font-bold mb-0">Actions in Selected Plan</h2>
                  <button
                    className="ml-2 focus:outline-none"
                    onClick={() => setIsActionsVisible(!isActionsVisible)}
                  >
                    {isActionsVisible ? '▲' : '▼'}
                  </button>
                </div>
                {isActionsVisible && (
                  <table className="text-sm min-w-full bg-neutral-800 rounded-lg overflow-hidden">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 bg-neutral-700 text-left">Task ID</th>
                        <th className="py-2 px-4 bg-neutral-700 text-left">Task Text</th>
                        <th className="py-2 px-4 bg-neutral-700 text-left">Type</th>
                        <th className="py-2 px-4 bg-neutral-700 text-left">Category</th>
                        <th className="py-2 px-4 bg-neutral-700 text-left">Required</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uniquePlanTasks.map((task) => (
                        <tr key={task.taskID}>
                          <td className="py-2 px-4">{task.taskID}</td>
                          <td className="py-2 px-4">{task.taskText}</td>
                          <td className="py-2 px-4">{taskTypes.find((type) => type.taskTypeID === task.taskTypeID)?.title || ''}</td>
                          <td className="py-2 px-4">{taskCategories.find((category) => category.taskCategoryID === task.taskCategoryID)?.title || ''}</td>
                          <td className="py-2 px-4">{task.scripts[selectedActionPlan?.scriptID]?.required ? 'Yes' : 'No'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  );
};

export default ActionPlanEditor;

