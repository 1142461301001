import 'tailwindcss/tailwind.css';
import React, { useState, useEffect } from 'react';

function ResponseInsight() {
  const [tenantGroupId, setTenantGroupId] = useState(null);
  const [region, setRegion] = useState(null);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const tenantId = url.searchParams.get('tenantGroupId');
    setTenantGroupId(tenantId);
    const regionCode = url.searchParams.get('region');
    setRegion(regionCode);
    const summaryParam = url.searchParams.get('summary');
    if (summaryParam) {
      const decodedSummary = decodeURIComponent(summaryParam);
      setSummary(decodedSummary);
    }
  }, []);

  return (
    <div className="bg-neutral-900 text-white min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full">
        <div className="bg-neutral-800 p-4 rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold">Insights</h1>
          {summary && 
            <p className="mt-4">{summary}</p>
          }
        </div>
      </div>
    </div>
  );
}

export default ResponseInsight;
