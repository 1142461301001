import React, { useState } from 'react';

function Dashboard() {
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);

  const tenantGroupId = new URLSearchParams(window.location.search).get('tenantGroupId');
  const region = new URLSearchParams(window.location.search).get('region');
  const dashboard = new URLSearchParams(window.location.search).get('dashboard');
  let baseUrl = 'https://virtualoperator.eastus.cloudapp.azure.com/api';  // 'http://127.0.0.1:5001';  //  

  const handleSummarize = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${baseUrl}/dashboardsummary?tenantGroupId=${tenantGroupId}&region=${region}&dashboard=${dashboard}`);

      const data = await response.json();
      setSummary(data.summary);
    } catch (error) {
      console.error('Error:', error);
    }

    setLoading(false);
  };

  return (
    <div className="bg-neutral-900 text-white min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full">
        <div className="bg-neutral-800 p-4 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">Dashboards</h1>
            <h2 className="text-sm">{decodeURIComponent(dashboard)}</h2>
          </div>
          {summary && <p className="mt-4 text-sm">{summary}</p>}
          <button
            onClick={handleSummarize}
            className="mt-4 py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white font-bold rounded"
            disabled={loading}
          >
            {loading ? 'Generating Summary...' : (summary ? 'Summarize Again' : 'Summarize')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;