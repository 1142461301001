import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getStraightPath } from 'reactflow';

const CustomEdgeRequired = ({ id, sourceX, sourceY, targetX, targetY, label }) => {
  const [edgePath, labelX, labelY] = getStraightPath({ sourceX, sourceY, targetX, targetY });

  // Unique ID for the marker
  const markerId = `red-arrow-${id}`;

  return (
    <>
      <svg style={{ position: 'absolute', width: 0, height: 0 }}>
        <defs>
          <marker
            id={markerId}
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="10"
            markerHeight="10"
            orient="auto-start-reverse"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" fill="#FF0000" />
          </marker>
        </defs>
      </svg>
      <BaseEdge 
        path={edgePath} 
        markerEnd={`url(#${markerId})`}
        style={{
          stroke: "#FF0000",
          zIndex: 1000
        }}
      />
      {label && (
        <EdgeLabelRenderer>
          <div
            className="bg-neutral-700 text-white text-xs rounded px-2 py-1"
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              pointerEvents: 'none',
              zIndex: 1001
            }}
          >
            {label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default CustomEdgeRequired;