import 'tailwindcss/tailwind.css';
import React, { useState, useEffect, useCallback } from 'react';
import { FaPause, FaPlay, FaLink } from 'react-icons/fa';

function Queue() {
  const [tenantGroupId, setTenantGroupId] = useState(null);
  const [region, setRegion] = useState(null);
  const [events, setEvents] = useState([]);
  const [numberOfOperators, setNumberOfOperators] = useState(0);
  const [virtualOperatorEnabled, setVirtualOperatorEnabled] = useState(true);
  const [allowPausing, setAllowPausing] = useState(true);
  const [error, setError] = useState(null);
  let baseUrl = 'https://virtualoperator.eastus.cloudapp.azure.com/api'; // 'http://127.0.0.1:5001';  //  

  useEffect(() => {
    const url = new URL(window.location.href);
    const tenantId = url.searchParams.get('tenantGroupId');
    setTenantGroupId(tenantId);
    const regionCode = url.searchParams.get('region');
    setRegion(regionCode);
  }, []);

  const fetchEvents = useCallback(async () => {
    try {
      const response = await fetch(`${baseUrl}/getCurrentEvents?tenantGroupId=${tenantGroupId}&region=${region}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      setEvents(data.events);
      setNumberOfOperators(data.numberOfOperators);
      setVirtualOperatorEnabled(data.virtualOperatorEnabled);
      setAllowPausing(data.allowPausing);
      setError(null);
    } catch (error) {
      console.error('Error fetching events:', error);
      if (error.message === "Failed to fetch"){
        error.message = "Virtual Operator Manager Offline"
      }
      setError("Error: "+error.message);
    }
  }, [tenantGroupId, region, baseUrl]);

  useEffect(() => {
    if (tenantGroupId && region) {
      fetchEvents();
      const interval = setInterval(fetchEvents, 2000);
      return () => clearInterval(interval);
    }
  }, [tenantGroupId, region, fetchEvents]);

  const pauseUnpauseEvent = async (eventId, paused) => {
    try {
      await fetch(`${baseUrl}/pauseUnpauseEvents?tenantGroupId=${tenantGroupId}&region=${region}&eventId=${eventId}&paused=${paused}`);
      fetchEvents();
    } catch (error) {
      console.error('Error pausing/unpausing event:', error);
    }
  };

  const pauseAllOperators = () => {
    pauseUnpauseEvent(-1, true);
  };

  const startNewVirtualOperator = async () => {
    try {
      await fetch(`${baseUrl}/startNewVirtualOperator?tenantGroupId=${tenantGroupId}&region=${region}`);
      fetchEvents();
    } catch (error) {
      console.error('Error starting new virtual operator:', error);
    }
  };

  const renderOperatorRows = () => {
    const rows = [];
    if (!virtualOperatorEnabled) {
      return rows;
    }
    for (let i = 0; i < numberOfOperators; i++) {
      const event = events[i];
      if (event) {
        rows.push(
          <li key={event.eventID} className="flex items-center justify-between my-2 text-sm">
            {allowPausing &&
              <button
                className={`w-6 h-6 rounded-full ${event.paused ? 'bg-green-500' : 'bg-red-500'} text-white mr-4 flex items-center justify-center flex-shrink-0`}
                onClick={() => pauseUnpauseEvent(event.eventID, !event.paused)}
              >
                {event.paused ? <FaPlay size={14} /> : <FaPause size={14} />}
              </button>
            }
            <div className="flex-grow overflow-hidden">
              <p className="max-w-[315px] text-sm whitespace-nowrap overflow-hidden text-ellipsis my-0">{event.eventTitle} | {event.groupTitle}{event.responseTitle ? (" | " + event.responseTitle) : ""}</p>
            </div>
            <button
              className="w-6 h-6 bg-blue-500 text-white rounded-full ml-4 flex items-center justify-center flex-shrink-0"
              onClick={() => window.open(`https://${region}.sureviewops.com/#/eventsearch/${event.eventID}`, '_blank')}
              title={event.eventID}
            >
              <FaLink size={14} />
            </button>
          </li>
        );
      } else {
        rows.push(
          <li key={i} className="flex items-center justify-between my-2 text-sm">
            {allowPausing &&
              <button
                className="w-6 h-6 rounded-full bg-gray-500 opacity-50 text-white mr-4 cursor-not-allowed flex items-center justify-center flex-shrink-0"
                disabled={true}
              >
                <FaPause size={14} />
              </button>
            }
            <p className="flex-grow m-0 mb-1">Waiting for next event</p>
          </li>
        );
      }
    }
    return rows;
  };

  return (
    <div className="bg-neutral-900 text-white min-h-screen flex items-center justify-center">
      <div className="h-full w-full m-4">
        <div className="bg-neutral-800 p-4 pt-3 rounded-lg shadow-lg">
          {error ? (
            <div className="text-red-500 text-lg font-bold mb-0 mt-1">{error}</div>
          ) : (
            <>
              <h1 className={`text-lg font-bold ${virtualOperatorEnabled ? "mb-3" : "mt-2"}`}>
                {virtualOperatorEnabled ? "Virtual Operator Status" : "Virtual Operators Disabled"}
              </h1>
              {virtualOperatorEnabled && (
                <>
                  <div className="flex justify-start mb-3">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded mr-4 text-sm"
                      onClick={startNewVirtualOperator}
                    >
                      Start New Virtual Operator
                    </button>
                    {allowPausing &&
                      <button
                        className="bg-gray-500 text-white px-4 py-2 rounded ml-4 text-sm"
                        onClick={pauseAllOperators}
                      >
                        Pause Virtual Operators
                      </button>
                    }

                  </div>
                  <div className="bg-neutral-700 p-3 pb-2 rounded">
                    <h2 className="text-sm font-bold mb-3">Active Virtual Operators</h2>
                    <ul className="mb-0 pl-3 pr-3">
                      {renderOperatorRows()}
                    </ul>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Queue;